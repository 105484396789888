import React, { useRef, useState } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import Toast from "../common/Toast";
import { toast } from "react-hot-toast";
import Modal from "../common/Modal";
import AddAffiliate from "./AddAffiliate";
import Input from "../common/Input";
import ImageViewer from "../common/ImageViewer";
const Table = ({ headings, data, selected }) => {
  const [affiliatesModal, setAffiliatesModal] = useState(false);
  const [filterString, setFilterString] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [imgUrl, setImgUrl] = useState("");
  const tableRef = useRef();
  const serverUrl = `${process.env.REACT_APP_SERVER_URL}`

  // pagination and filtering
  const usersPerPage = 10;
  const filteredData = filterString
    ? data.filter((user) =>
        user.affiliate?.email.toLowerCase().includes(filterString.toLowerCase())
      )
    : data;
  const totalUsers = filteredData.length;
  const lastUserIndex = currentPage * usersPerPage;
  const firstUserIndex = lastUserIndex - usersPerPage;
  const currentUsers = filteredData.slice(firstUserIndex, lastUserIndex);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalUsers / usersPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  function handleCopyClick(textToCopy) {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.custom((t) => (
          <Toast type="success" msg="Text copied to clipboard" t={t} />
        ));
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  }

  const renderPageNumbers = pageNumbers.map((number) => {
    return (
      <li
        key={number}
        id={number}
        onClick={handleClick}
        className={`px-4 py-2 rounded-md cursor-pointer ${
          currentPage === number
            ? "bg-blue-500 text-white"
            : "bg-white text-blue-500"
        }`}
      >
        {number}
      </li>
    );
  });

  const handleInputChange = (event) => {
    setFilterString(event.target.value); // update the filter string when the user enters text
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: selected + "table",
    sheet: selected,
  });
  return (
    <>
      <Modal
        closeModal={(val) => setAffiliatesModal(val)}
        isOpen={affiliatesModal}
        title="add affiliate"
      >
        <AddAffiliate closeModal={(val) => setAffiliatesModal(val)} />
      </Modal>
      <Modal
        closeModal={(val) => setImgUrl("")}
        isOpen={imgUrl}
        title="document preview"
      >
        <ImageViewer url={imgUrl} closeModal={(val) => setImgUrl("")} />
      </Modal>

      {selected === "users" && (
        <div className="flex gap-2  justify-end">
          <div className="w-1/4">
            <Input
              label="filter by affiliate using there email"
              id="affiliate-input"
              value={filterString}
              handleChange={handleInputChange}
            />
          </div>
          <button
            onClick={onDownload}
            className="bg-blue-500 w-fit text-white p-2 px-4 border-none capitalize rounded-lg"
          >
            Export table
          </button>
        </div>
      )}
      {selected === "affiliates" && (
        <button
          onClick={() => setAffiliatesModal(true)}
          className="bg-blue-500 w-fit capitalize text-white ml-auto p-2 px-4 border-none flex justify-end rounded-lg"
        >
          add affiliate
        </button>
      )}
      <div className="w-full overflow-x-auto">
        <table
          ref={tableRef}
          className="w-full text-sm text-left text-gray-500 mb-4"
        >
          <thead className="text-gray-700 capitalize bg-gray-50 text-base">
            <tr>
              {selected === "users" ? (
                <>
                  <th className="p-4 hidden">suffix</th>
                  <th className="p-4">first Name</th>
                  <th className="p-4 hidden">middle Name</th>
                  <th className="p-4">last Name</th>
                  <th className="p-4 hidden">assistance recipient</th>
                  <th className="p-4 hidden">
                    adult Assistance Reception Status
                  </th>
                  <th className="p-4 hidden">living with adult</th>
                  <th className="p-4">email</th>
                  <th className="p-4 hidden">phone</th>
                  <th className="p-4 hidden">date of birth</th>
                  <th className="p-4 hidden">ssn</th>
                  <th className="p-4 hidden">city</th>
                  <th className="p-4 hidden">state</th>
                  <th className="p-4 hidden">zip code</th>
                  <th className="p-4 hidden">address 1</th>
                  <th className="p-4 hidden">address 2</th>
                  <th className="p-4 hidden">address status</th>
                  <th className="p-4 hidden">reach</th>
                  <th className="p-4 hidden">
                    national Verifier Application Id
                  </th>
                  <th className="p-4 hidden">school</th>
                  <th className="p-4">program</th>
                  <th className="p-4 hidden">people in household:</th>

                  <th className="p-4">identity document</th>
                  <th className="p-4">supporting document</th>
                  <th className="p-4 hidden">free phone</th>
                  <th className="p-4 hidden">Free Replacement SIM</th>
                  <th className="p-4 hidden">phone upgrade</th>
                  <th className="p-4 hidden">tablet bundle</th>
                  <th className="p-4 hidden">unlimited data plan</th>
                  <th className="p-4">affiliate name</th>
                </>
              ) : (
                headings.map((heading) => (
                  <th key={heading} className="p-4 capitalize">
                    {heading}
                  </th>
                ))
              )}
            </tr>
          </thead>
          <tbody className=" capitalize">
            {selected === "users" &&
              currentUsers.map((user, index) => (
                <tr
                  className="bg-white border-b dark:border-gray-700"
                  key={index}
                >
                  <td className="p-4 hidden">{user.suffix}</td>
                  <td
                    scope="row"
                    className="p-4 font-medium text-gray-900 whitespace-nowrap capitalize"
                  >
                    {user.firstName}
                  </td>
                  <td className="p-4 hidden">{user.middleName}</td>
                  <td className="p-4">{user.lastName}</td>
                  <td className="p-4 hidden">{user.assistanceRecipient}</td>
                  <td className="p-4 hidden">
                    {!user.adultAssistanceReceptionStatus ? "true" : "false"}
                  </td>
                  <td className="p-4 hidden">
                    {!user.livingWithAdult ? "true" : "false"}
                  </td>
                  <td className="p-4">{user.email}</td>
                  <td className="p-4 hidden">{user.phone}</td>
                  <td className="p-4 hidden">{user.dob}</td>
                  <td className="p-4 hidden">{user.ssn}</td>
                  <td className="p-4 hidden">{user.city}</td>
                  <td className="p-4 hidden">{user.state}</td>
                  <td className="p-4 hidden">{user.zip}</td>
                  <td className="p-4 hidden">{user.address1}</td>
                  <td className="p-4 hidden">{user.address2}</td>
                  <td className="p-4 hidden">{user.addressStatus}</td>
                  <td className="p-4 hidden">{user.reach}</td>
                  <td className="p-4 hidden">
                    {user.nationalVerifierApplicationId}
                  </td>

                  <td className="p-4 hidden">{user.school}</td>
                  <td className="p-4">{user.program?.name || "N/A"}</td>
                  <td className="p-4 hidden">{user.houseHold || "N/A"}</td>

                  <td
                    className="p-4 text-blue-500 cursor-pointer"
                    onClick={() =>
                      setImgUrl(serverUrl + user.identityDocument?.image || "")
                    }
                  >
                    {user.identityDocument ? serverUrl + user.identityDocument.image : " N/A"}
                  </td>
                  <td
                    className="p-4 text-blue-500 cursor-pointer"
                    onClick={() =>
                      setImgUrl( serverUrl + user.supportingDocument?.image || "")
                    }
                  >
                    {user.supportingDocument? serverUrl + user.supportingDocument.image : "N/A"}
                  </td>
                  <td className="p-4 hidden">
                    {user.freePhone ? "true" : "false"}
                  </td>
                  <td className="p-4 hidden">
                    {user.freeReplacementSIM ? "true" : "false"}
                  </td>
                  <td className="p-4 hidden">
                    {user.phoneUpgrade ? "true" : "false"}
                  </td>
                  <td className="p-4 hidden">
                    {user.tabletBundle ? "true" : "false"}
                  </td>
                  <td className="p-4 hidden">
                    {user.unlimitedDataPlan ? "true" : "false"}
                  </td>
                  <td className="p-4">
                    {user.affiliate
                      ? user.affiliate?.firstName +
                        " " +
                        user.affiliate?.lastName
                      : "N/A"}
                  </td>
                </tr>
              ))}
            {selected === "affiliates" &&
              data.map((affiliate, index) => (
                <tr className="bg-white border-b dark:border-gray-700">
                  <td className="px-6 py-4">{affiliate._id}</td>
                  <td className="px-6 py-4">{affiliate.firstName}</td>
                  <td className="px-6 py-4">{affiliate.lastName}</td>
                  <td className="px-6 py-4">{affiliate.email}</td>
                  <td className="px-6 py-4">
                    <p
                      className=" cursor-pointer text-blue-500"
                      onClick={() =>
                        handleCopyClick(
                          `${process.env.REACT_APP_DOMAIN}/?affiliate=${affiliate._id}`
                        )
                      }
                    >
                      click to copy link
                    </p>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <ul id="page-numbers" className="flex items-center gap-2">
        {renderPageNumbers}
      </ul>
    </>
  );
};

export default Table;
