import React from "react";

const Modal = ({ children, title, isOpen, closeModal }) => {
  if (!isOpen) return null;
  return (
    <div className="fixed top-0 left-0 w-full h-screen z-50 bg-black/30 flex items-center justify-center">
      <div
        className="absolute top-0 left-0 h-full w-full bg-transparent"
        onClick={() => closeModal(false)}
      ></div>
      <div className="bg-white w-11/12 md:w-3/5 lg:w-1/2 overflow-hidden rounded-sm shadow-lg relative z-40">
        <div className="flex items-center justify-between p-4 xl:px-8 border-b bg-blue-600 text-white border-gray-300">
          <h3 className="text-lg font-semibold capitalize">{title}</h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            onClick={() => closeModal(false)}
            className="w-6 h-6 cursor-pointer"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
