import React from "react";

const Input = ({
  type,
  label,
  id,
  value,
  error,
  handleChange,
  required,
  name,
  readOnly,
  disabled,
  onBlur,
}) => {
  return (
    <div className="relative z-40 w-full group">
      <input
        disabled={disabled}
        required={required}
        type={type || "text"}
        name={name}
        value={value}
        readOnly={readOnly || false}
        id={id}
        onChange={(e) => handleChange(e)}
        onBlur={onBlur}
        className={`block py-3 px-2 w-full  text-N40  border  border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-primary peer rounded-sm ${
          disabled ? "bg-blue-100" : "bg-transparent"
        }`}
        placeholder=" "
      />
      <label
        htmlFor={id}
        className="absolute text-sm text-N40 duration-300 transform -translate-y-4 scale-75 top-4 -z-10 origin-[0] left-2.5 peer-focus:text-N80  capitalize peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
      >
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      {error && (
        <p className="text-red-500 text-[10px] capitalize mt-1">{error}</p>
      )}
    </div>
  );
};

export default Input;
