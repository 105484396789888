import * as Yup from "yup";
import Input from "../common/Input";
import Button from "../common/Button";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import Toast from "../common/Toast";
import { ADD_AFFILIATE } from "../../apollo/mutations";
import { useMutation } from "@apollo/client";
import { GET_AFFILIATES } from "../../apollo/queries";
const AddAffiliate = ({ closeModal }) => {
  const affiliateSchema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().email().required(),
  });

  const [addAffiliate, { data, loading, error }] = useMutation(ADD_AFFILIATE, {
    onCompleted: (data) => {
      toast.custom((t) => (
        <Toast type="success" msg="affiliate added successfully" t={t} />
      ));
      closeModal(false);
    },
    onError: (error) => {
      toast.custom((t) => <Toast type="error" msg={error.message} t={t} />);
    },
  });

  const initialValues = { firstName: "", lastName: "", email: "" };
  const handleSubmit = (values) => {
    if (values) {
      addAffiliate({
        variables: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
        },
        refetchQueries: GET_AFFILIATES,
      });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: affiliateSchema,
    onSubmit: handleSubmit,
  });
  return (
    <form
      onSubmit={formik.handleSubmit}
      className="p-4 lg:p-8 space-y-4 2xl:space-y-6"
    >
      <Input
        label="first name"
        name="firstName"
        handleChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.firstName}
        error={formik.touched.firstName && formik.errors.firstName}
      />
      <Input
        label="last name"
        name="lastName"
        handleChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.lastName}
        error={formik.touched.lastName && formik.errors.lastName}
      />
      <Input
        label="email"
        name="email"
        handleChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        error={formik.touched.email && formik.errors.email}
      />
      <Button
        type="submit"
        handleClick={handleSubmit}
        custom="bg-blue-500 text-white w-full py-3"
      >
        add affiliate
      </Button>
    </form>
  );
};

export default AddAffiliate;
