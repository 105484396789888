import { useContext, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../../common/Button";
import { UserContext } from "../../../context/userContext";
import { toast } from "react-hot-toast";
import Toast from "../../common/Toast";
const FourthStep = ({ nextStep, prevStep }) => {
  const { user, setUser } = useContext(UserContext);
  const fileRef = useRef(null);

  const identitySchema = Yup.object().shape({
    identityDocument: Yup.mixed()
      .test("is-file-too-big", "File exceeds 5MB", () => {
        let valid = true;
        const files = fileRef?.current?.files;
        if (files) {
          const fileArr = Array.from(files);
          fileArr.forEach((file) => {
            const size = file.size / 1024 / 1024;
            if (size > 5) {
              valid = false;
            }
          });
        }
        return valid;
      })
      .test(
        "is-file-type-valid",
        "Only PNG, JPG, JPEG, WEBP, PDF files are allowed",
        (value) => {
          let valid = true;
          const files = fileRef?.current?.files;
          if (files) {
            const fileArr = Array.from(files);
            fileArr.forEach((file) => {
              const fileType = file.type.split("/")[1].toLowerCase();
              if (!["png", "jpg", "jpeg", "webp", "pdf"].includes(fileType)) {
                valid = false;
              }
            });
          }
          return valid;
        }
      )
      .nullable(),
    optInSim: Yup.bool(),
    optInForOtherPrograms: Yup.bool(),
    financialBurden: Yup.bool(),
  });

  const initialValues = {
    optInSim: user.optInSim,
    optInForOtherPrograms: user.optInForOtherPrograms,
    identityDocument: "",
    financialBurden: user.financialBurden,
  };

  const handleFileUpload = async (file, sim, program, finance) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/upload`;
    const formData = new FormData();

    formData.append("document", file);
    formData.append("type", "Identity");
    const options = {
      method: "POST",
      body: formData,
    };
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      toast.custom((t) => <Toast type="success" msg={data.message} t={t} />);
      setUser({
        ...user,
        identityDocument: data.data._id,
        optInSim: sim,
        optInForOtherPrograms: program,
        financialBurden: finance,
      });
    } catch (error) {
      toast.custom((t) => <Toast type="error" msg={error.message} t={t} />);
    }
  };

  const handleSubmit = (values) => {
    if (values) {
      if (fileRef.current.files[0]) {
        handleFileUpload(
          fileRef.current.files[0],
          values.optInSim,
          values.optInForOtherPrograms,
          values.financialBurden
        );
        nextStep();
        return;
      }
      setUser({
        ...user,
        optInSim: values.optInSim,
        optInForOtherPrograms: values.optInForOtherPrograms,
        financialBurden: values.financialBurden,
        identityDocument: null,
      });
      nextStep();
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: identitySchema,
    onSubmit: handleSubmit,
  });
  return (
    <form onSubmit={formik.handleSubmit} className="space-y-4">
      <div className="flex flex-col justify-start gap-2 md:w-1/2 lg:w-2/5 2xl:w-1/3">
        <p className=" text-N20 font-medium capitalize">
          upload a document that proves your identity (Driver's license, State
          id...)?
        </p>
        <p className="text-sm text-N50">
          Although it is not mandatory, please upload any benefit letter that
          may assist enrollment process.
        </p>
        <label>
          <input
            className="text-sm cursor-pointer w-full mb-2 hidden"
            type="file"
            name="identityDocument"
            id="identityDocument"
            ref={fileRef}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="text-center bg-white text-blue-500 border border-blue-300 rounded font-semibold cursor-pointer p-1 px-3">
            Select
          </div>
          {formik.touched.identityDocument &&
            formik.errors.identityDocument && (
              <p className="text-red-500 text-[10px] capitalize mt-1">
                {formik.errors.identityDocument}
              </p>
            )}
        </label>
      </div>
      <div className=" space-y-2 flex items-center gap-2">
        <input
          type="checkbox"
          name="optInSim"
          onChange={formik.handleChange}
          checked={formik.values.optInSim}
          onBlur={formik.handleBlur}
          id="sim"
          className="mt-1"
        />
        <label htmlFor="sim" className="capitalize text-N50 text-sm">
          if your not approved for a tablet you may qualify for a sim only opt
          out if you dont want sim
        </label>
      </div>
      <div className=" space-y-2 flex items-center gap-2">
        <input
          type="checkbox"
          name="optInForOtherPrograms"
          onChange={formik.handleChange}
          checked={formik.values.optInForOtherPrograms}
          onBlur={formik.handleBlur}
          id="program"
          className="mt-1"
        />
        <label htmlFor="program" className="capitalize text-N50 text-sm">
          opt in if you like to hear about other programs
        </label>
      </div>

      <p className="text-N30 font-semibold">
        The Affordable Connectivity Program (ACP) allows qualifying subscribers
        to claim a one time discount toward a tablet. The FCC requires us to
        charge a copay amount. Our Copay for an Android Tablet with 4G LTE
        Connectivity is a one-time $11.00 (A transaction of $11.00 will be
        drafted when the prospect subscriber is approved to receive a Tablet)
      </p>
      <div className=" space-y-2 flex items-center gap-2">
        <input
          type="checkbox"
          name="financialBurden"
          onChange={formik.handleChange}
          checked={formik.values.financialBurden}
          onBlur={formik.handleBlur}
          id="financial"
          className="mt-1"
        />
        <label htmlFor="financial" className="capitalize text-N50 text-sm">
          if this a financial burden for you please click here to see you may
          qualify for further assitance. You will not be charged unles approved.
        </label>
      </div>
      <p className="text-sm text-N50">
        If approved you will recieve an email letting you know if your tablet
        would be shipped with USPS or a local designated pickup point.
      </p>
      <p className="text-sm text-N50">
        If not approved you may be requested for additional documents
      </p>

      <div className="flex items-center justify-between gap-4">
        <Button handleClick={prevStep} custom="border border-N90 w-32">
          &lt; back
        </Button>
        <Button
          type="submit"
          handleClick={handleSubmit}
          custom="border border-blue-500 text-blue-500 w-36"
        >
          continue &gt;
        </Button>
      </div>
    </form>
  );
};

export default FourthStep;
