import React, { useContext, useEffect, useState } from "react";
import Table from "../components/layout/Table";
import { useQuery } from "@apollo/client";
import { GET_AFFILIATES, GET_USERS } from "../apollo/queries";
import Loader from "../components/common/Loader";
import Toast from "../components/common/Toast";
import { toast } from "react-hot-toast";
import { UserContext } from "../context/userContext";
import { useNavigate } from "react-router-dom";
import Button from "../components/common/Button";
const Admin = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [selected, setSelected] = useState("users");
  const { data, loading, error } = useQuery(GET_USERS);
  const {
    data: affiliatesData,
    loading: affiliatesLoading,
    error: affiliatesError,
  } = useQuery(GET_AFFILIATES);

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, []);

  if (loading || affiliatesLoading) return <Loader />;
  if (error || affiliatesError)
    toast.custom((t) => <Toast type="error" msg={error.message} t={t} />);

  return (
    <div className="bg-gray-50 h-body">
      <div className="flex items-center justify-between w-11/12 mx-auto">
        <h1 className="text-3xl capitalize py-8 font-semibold text-blue-900 ">
          admin dashboard
        </h1>
        <Button
          handleClick={handleLogout}
          custom="border-blue-500 text-blue-500 border h-min"
        >
          logout
        </Button>
      </div>

      <div className="flex w-11/12 items-start mx-auto">
        <div className="w-1/5 bg-blue-600 h-44 capitalize divide-y divide-white/30">
          <h1
            onClick={() => setSelected("users")}
            className={`text-white p-4 hover:bg-white/10 cursor-pointer transition-colors duration-300 ease-in-out ${
              selected === "users" && "bg-white/10"
            }`}
          >
            Users
          </h1>
          <h1
            onClick={() => setSelected("affiliates")}
            className={`text-white p-4 hover:bg-white/10 cursor-pointer transition-colors duration-300 ease-in-out ${
              selected === "affiliates" && "bg-white/10"
            }`}
          >
            affiliates
          </h1>
        </div>

        <div className="w-4/5 p-4">
          <div className="flex items-center justify-between w-full">
            <h1 className="text-lg font-semibold mb-4 text-blue-900 capitalize">
              {selected}
            </h1>
          </div>
          <div className="w-full overflow-x-auto">
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              {selected === "users" && (
                <Table selected={selected} data={data?.users} />
              )}
              {selected === "affiliates" && (
                <Table
                  headings={[
                    "affiliate id",
                    "first name",
                    "last name",
                    "email",
                    "link",
                  ]}
                  selected={selected}
                  data={affiliatesData?.affiliates}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
