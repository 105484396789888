import { useEffect } from "react";
import Toast from "../components/common/Toast";
import Loader from "../components/common/Loader";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
function Completion() {
  const navigate = useNavigate();
  useEffect(() => {
    toast.custom((t) => (
      <Toast type="success" msg="You're Successfully registered" t={t} />
    ));
    navigate("/");
  }, []);

  return (
    <div className="relative w-full h-screen">
      <Loader />
    </div>
  );
}

export default Completion;
