import React, { useContext, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Button from "../../common/Button";
import Toast from "../../common/Toast";
import { UserContext } from "../../../context/userContext";
const Income = ({ closeModal }) => {
  const { user, setUser } = useContext(UserContext);
  const [income, setIncome] = useState(29160);
  const fileRef = useRef(null);
  const navigate = useNavigate();

  const incomeSchema = Yup.object().shape({
    household: Yup.number(),
    income: Yup.boolean().required(),
    SupportingDocument: Yup.mixed()
      .required("file is required")
      .test("is-file-too-big", "File exceeds 5MB", () => {
        let valid = true;
        const files = fileRef?.current?.files;
        if (files) {
          const fileArr = Array.from(files);
          fileArr.forEach((file) => {
            const size = file.size / 1024 / 1024;
            if (size > 5) {
              valid = false;
            }
          });
        }
        return valid;
      })
      .test(
        "is-file-type-valid",
        "Only PNG, JPG, JPEG, WEBP, PDF files are allowed",
        (value) => {
          let valid = true;
          const files = fileRef?.current?.files;
          if (files) {
            const fileArr = Array.from(files);
            fileArr.forEach((file) => {
              const fileType = file.type.split("/")[1].toLowerCase();
              if (!["png", "jpg", "jpeg", "webp", "pdf"].includes(fileType)) {
                valid = false;
              }
            });
          }
          return valid;
        }
      )
      .nullable(),
  });

  const initialValues = {
    household: user.houseHold,
    income: user.validIncome,
    SupportingDocument: null,
  };

  const handleFileUpload = async (file, household, validIncome) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/upload`;
    const formData = new FormData();

    formData.append("document", file);
    formData.append("type", "Supporting");
    const options = {
      method: "POST",
      body: formData,
    };
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      toast.custom((t) => <Toast type="success" msg={data.message} t={t} />);
      setUser({
        ...user,
        houseHold: household,
        validIncome: validIncome,
        SupportingDocument: data.data._id,
      });
      closeModal(false);
    } catch (error) {
      toast.custom((t) => <Toast type="error" msg={error.message} t={t} />);
    }
  };

  const handleSubmit = (values) => {
    if (values) {
      if (values.income === "false") {
        toast.custom((t) => (
          <Toast
            type="error"
            msg="Sorry, you're not eligible for the program"
            t={t}
          />
        ));
        navigate("/");
        closeModal(false);
        return;
      }
      if (fileRef.current.files.length === 0) {
        setUser({
          ...user,
          houseHold: values.household,
          validIncome: values.income,
        });
        closeModal(false);
        return;
      }

      handleFileUpload(
        fileRef.current.files[0],
        values.household,
        values.income
      );
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: incomeSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    switch (+formik.values.household) {
      case 1:
        setIncome(29160);
        break;
      case 2:
        setIncome(39440);
        break;
      case 3:
        setIncome(49720);
        break;
      case 4:
        setIncome(60000);
        break;
      case 5:
        setIncome(70280);
        break;
      case 6:
        setIncome(80560);
        break;
      case 7:
        setIncome(90840);
        break;
      case 8:
        setIncome(101120);
        break;
      default:
        setIncome(29160);
        break;
    }
  }, [formik.values.household]);

  return (
    <div className="p-4 xl:p-8 space-y-4">
      <div className=" space-y-2">
        <div className="flex items-start gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#3b82f6"
            className="min-w-6 w-6 min-h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
            />
          </svg>
          <p className="text-sm text-N60 w-full">
            The following table will let you know the maximum income they can
            make to qualify based on how many people are in the household.
          </p>
        </div>
        <div className="flex items-start gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#3b82f6"
            className="min-w-6 w-6 min-h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
            />
          </svg>
          <p className="text-sm text-N60 w-full">
            Each additional person adds 10,280 per year to the income insert
            screen
          </p>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit} className="space-y-6">
        <div className=" space-y-2">
          <p className="capitalize text-N10 font-medium">
            how many people are in your household?
          </p>
          <div className="flex items-center justify-between flex-wrap gap-4">
            <div className="flex items-center gap-2 text-N30 text-sm">
              <input
                type="radio"
                id="household1"
                checked={formik.values.household == 1}
                name="household"
                onChange={formik.handleChange}
                value="1"
              />
              <label htmlFor="household1">1</label>
            </div>
            <div className="flex items-center gap-2 text-N30 text-sm">
              <input
                type="radio"
                id="household2"
                checked={formik.values.household == 2}
                name="household"
                onChange={formik.handleChange}
                value="2"
              />
              <label htmlFor="household2">2</label>
            </div>
            <div className="flex items-center gap-2 text-N30 text-sm">
              <input
                type="radio"
                id="household3"
                checked={formik.values.household == 3}
                name="household"
                onChange={formik.handleChange}
                value="3"
              />
              <label htmlFor="household3">3</label>
            </div>
            <div className="flex items-center gap-2 text-N30 text-sm">
              <input
                type="radio"
                id="household4"
                checked={formik.values.household == 4}
                name="household"
                onChange={formik.handleChange}
                value="4"
              />
              <label htmlFor="household4">4</label>
            </div>
            <div className="flex items-center gap-2 text-N30 text-sm">
              <input
                type="radio"
                id="household5"
                checked={formik.values.household == 5}
                name="household"
                onChange={formik.handleChange}
                value="5"
              />
              <label htmlFor="household5">5</label>
            </div>
            <div className="flex items-center gap-2 text-N30 text-sm">
              <input
                type="radio"
                id="household6"
                checked={formik.values.household == 6}
                name="household"
                onChange={formik.handleChange}
                value="6"
              />
              <label htmlFor="household6">6</label>
            </div>
            <div className="flex items-center gap-2 text-N30 text-sm">
              <input
                type="radio"
                id="household7"
                checked={formik.values.household == 7}
                name="household"
                onChange={formik.handleChange}
                value="7"
              />
              <label htmlFor="household7">7</label>
            </div>
            <div className="flex items-center gap-2 text-N30 text-sm">
              <input
                type="radio"
                id="household8"
                checked={formik.values.household == 8}
                name="household"
                onChange={formik.handleChange}
                value="8"
              />
              <label htmlFor="household8">8</label>
            </div>
          </div>
        </div>
        <div className=" space-y-2 capitalize">
          <p className=" text-N10 font-medium">
            is your annual income less than {income}?
          </p>
          <div className="flex items-center justify-between gap-4 sm:w-3/5 xl:w-1/2">
            <div className="flex items-center gap-2 text-N30 text-sm">
              <input
                type="radio"
                id="yes"
                checked={formik.values.income === "true"}
                name="income"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={true}
              />
              <label htmlFor="yes">yes</label>
            </div>
            <div className="flex items-center gap-2 text-N30 text-sm">
              <input
                type="radio"
                id="no"
                checked={formik.values.income === "false"}
                name="income"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={false}
              />
              <label htmlFor="no">no</label>
            </div>
          </div>
        </div>
        {formik.values.income === "true" && (
          <div className="flex flex-col justify-start gap-2 text-center">
            <p className=" text-N10 text-left font-medium capitalize">
              upload supporting document?
            </p>
            <p className="text-xs text-N60 text-left">
              Acceptable document types W-2 last year, Paystubs 3, Federal or
              State income tax return Social Security Statement of Benefits
            </p>
            <label>
              <input
                className="text-sm cursor-pointer w-full mb-2 hidden"
                type="file"
                name="SupportingDocument"
                id="SupportingDocument"
                ref={fileRef}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <div className="text bg-white text-blue-500 border border-blue-300 rounded font-semibold cursor-pointer p-1 px-3">
                Select
              </div>
              {formik.touched.SupportingDocument &&
                formik.errors.SupportingDocument && (
                  <p className="text-red-500 text-[10px] capitalize mt-1">
                    {formik.errors.SupportingDocument}
                  </p>
                )}
            </label>
          </div>
        )}
        <Button
          type="submit"
          handleClick={handleSubmit}
          custom="bg-blue-500 w-full text-white py-3"
        >
          continue
        </Button>
      </form>
    </div>
  );
};

export default Income;
