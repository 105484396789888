import { createContext, useState } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    _id: new Date().getTime(),
    firstName: "",
    lastName: "",
    middleName: "",
    suffix: "",
    ssn: "",
    dob: "",
    reach: "Phone",
    email: "",
    phone: "",
    assistanceRecipient: "Myself",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    addressStatus: "",
    livingWithAdult: true,
    adultAssistanceReceptionStatus: true,
    taxTogether: false,
    nationalVerifierApplicationId: "",
    program: null,
    programName: "",
    school: "",
    contactConsent: false,
    affordableConnectivityProgramConsent: false,
    SupportingDocument: "",
    identityDocument: "",
    optInSim: true,
    optInForOtherPrograms: true,
    houseHold: 1,
    validIncome: true,
    affiliate: "",
    financialBurden: false,
    freePhone: false,
    freeReplacementSIM: false,
    phoneUpgrade: false,
    tabletBundle: false,
    unlimitedDataPlan: false,
  });

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
