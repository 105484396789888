import React, { useContext, useState } from "react";
import Input from "../../common/Input";

import ProgramCard from "./ProgramCard";
import Button from "../../common/Button";
import Modal from "../../common/Modal";
import FileUpload from "./FileUpload";
import PellGrant from "./PellGrant";
import { UserContext } from "../../../context/userContext";
import { useQuery } from "@apollo/client";
import { GET_PROGRAMS } from "../../../apollo/queries";
import Loader from "../../common/Loader";
const ThirdStep = ({ prevStep, nextStep }) => {
  const { user, setUser } = useContext(UserContext);
  const [selectedProgram, setSelectedProgram] = useState(user.program);
  const [note, setNote] = useState("");

  const { data, loading, error } = useQuery(GET_PROGRAMS);

  if (loading)
    return (
      <div className="relative w-full h-screen">
        <Loader />
      </div>
    );

  if (error) console.log(error);

  const handleProgramChange = (val) => {
    setUser({ ...user, school: "", SupportingDocument: null });

    setSelectedProgram(val);
  };

  const handleSubmit = () => {
    if (selectedProgram === "646facddb6fea31d6c975a4b" && user.school === "") {
      return;
    }
    if (selectedProgram === "646facddb6fea31d6c975a4b" && user.school !== "") {
      setUser({
        ...user,
        program: selectedProgram,
        programName: data?.Programs.find(
          (program) => program._id === selectedProgram
        ).name,
      });
      nextStep();
      return;
    }
    if (selectedProgram) {
      setUser({
        ...user,
        program: selectedProgram,
        programName: data?.Programs.find(
          (program) => program._id === selectedProgram
        ).name,
      });
      nextStep();
    }
  };

  return (
    <div>
      <div>
        <div className="xl:w-1/4 lg:w-1/3 md:w-1/2 mb-4">
          <Input label="national verifier application ID(Optional)" />
        </div>
        <div className=" space-y-2 mb-8 xl:mb-12">
          <h3 className="font-semibold sm:text-lg text-N30 capitalize">
            qualify for the affordable connectivity program
          </h3>
          <p className="text-sm text-N60 w-11/12">
            Select the applicable program from below to show that you, your
            dependent, or someone in your household qualifies for Affordable
            Connectivity Program. You can qualify through some government
            assistance programs or through your income (you do not need to
            qualify through both).
          </p>
          <span className="text-sm text-red-400">
            Note: You can select only one from the programs listed below.
          </span>
          <p className="text-sm text-red-400">
            Note: if you select federal pell grant you need to provide your
            school name.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 xl:gap-6 mb-6">
          {data?.Programs.map((program) => (
            <React.Fragment key={program._id}>
              <ProgramCard
                selected={selectedProgram}
                handleClick={handleProgramChange}
                img={program._id}
                title={program.name}
                value={program._id}
              />
            </React.Fragment>
          ))}
        </div>

        <div className="flex items-center justify-between gap-4 mt-4">
          <Button handleClick={prevStep} custom="border border-N90 w-32">
            &lt; back
          </Button>
          <Button
            type="submit"
            handleClick={handleSubmit}
            custom="border border-blue-500 text-blue-500 w-36"
          >
            continue &gt;
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ThirdStep;
