import React, { useContext, useRef } from "react";
import Input from "../../common/Input";
import Button from "../../common/Button";
import { UserContext } from "../../../context/userContext";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import Toast from "../../common/Toast";

const PellGrant = ({ closeModal }) => {
  const fileRef = useRef(null);
  const { user, setUser } = useContext(UserContext);
  const userSchema = Yup.object().shape({
    school: Yup.string().required("school name is required"),
    SupportingDocument: Yup.mixed()
      .required("file is required")
      .test("is-file-too-big", "File exceeds 5MB", () => {
        let valid = true;
        const files = fileRef?.current?.files;
        if (files) {
          const fileArr = Array.from(files);
          fileArr.forEach((file) => {
            const size = file.size / 1024 / 1024;
            if (size > 5) {
              valid = false;
            }
          });
        }
        return valid;
      })
      .test(
        "is-file-type-valid",
        "Only PNG, JPG, JPEG, WEBP, PDF files are allowed",
        (value) => {
          let valid = true;
          const files = fileRef?.current?.files;
          if (files) {
            const fileArr = Array.from(files);
            fileArr.forEach((file) => {
              const fileType = file.type.split("/")[1].toLowerCase();
              if (!["png", "jpg", "jpeg", "webp", "pdf"].includes(fileType)) {
                valid = false;
              }
            });
          }
          return valid;
        }
      ),
  });

  const initialValues = { school: "", SupportingDocument: "" };

  const handleFileUpload = async (file, values) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/upload`;
    const formData = new FormData();

    formData.append("document", file);
    formData.append("type", "Supporting");
    const options = {
      method: "POST",
      body: formData,
    };
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      toast.custom((t) => <Toast type="success" msg={data.message} t={t} />);
      setUser({
        ...user,
        SupportingDocument: data.data._id,
        school: values.school,
      });
      closeModal(false);
    } catch (error) {
      toast.custom((t) => <Toast type="error" msg={error.message} t={t} />);
    }
  };

  const handleSubmit = (values) => {
    if (values) {
      handleFileUpload(fileRef.current.files[0], values);
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema: userSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit} className="p-4 xl:p-8 space-y-4">
      <div className="flex items-start gap-2 flex-col justify-between">
        <p className="text-lg font-semibold capitalize text-N40">
          school name:
        </p>
        <div className="w-3/5">
          <Input
            value={formik.values.school}
            error={formik.errors.school}
            handleChange={formik.handleChange}
            label="school name"
            name="school"
          />
        </div>
      </div>
      <div className="flex flex-col gap-2 w-max items-start">
        <p className="text-lg font-semibold capitalize text-N40">
          Supporting Document:
        </p>
        <label>
          <input
            className="text-sm cursor-pointer w-full mb-4 text-center mx-auto"
            type="file"
            name="SupportingDocument"
            id="SupportingDocument"
            ref={fileRef}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          {formik.touched.SupportingDocument &&
            formik.errors.SupportingDocument && (
              <p className="text-red-500 text-[10px] capitalize my-1">
                {formik.errors.SupportingDocument}
              </p>
            )}
        </label>
      </div>
      <div className="flex justify-center">
        <Button
          type="submit"
          handleClick={handleSubmit}
          custom="bg-blue-500 text-white w-36"
        >
          done
        </Button>
      </div>
    </form>
  );
};

export default PellGrant;
