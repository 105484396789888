import React from "react";

const StepperHeader = ({ currentStep }) => {
  return (
    <div className="w-11/12 mx-auto flex items-center gap-2 h-full pt-12 xl:pt-16">
      <span
        className={`md:min-w-[2.5rem] min-w-[2rem] w-8 md:w-10 h-8 md:h-10 md:min-h-[2.5rem] min-h-[2rem] rounded-full   flex items-center justify-center  font-semibold ${
          currentStep >= 1
            ? "text-white bg-blue-500"
            : "text-blue-500 border border-blue-500"
        }`}
      >
        1
      </span>
      <p className=" capitalize text-xs hidden lg:block">
        personal information
      </p>
      <span
        className={`w-full h-[1px]  ${
          currentStep > 1 ? "bg-blue-500" : "bg-N90"
        }`}
      ></span>
      <span
        className={` md:min-w-[2.5rem] min-w-[2rem] w-8 md:w-10 h-8 md:h-10 md:min-h-[2.5rem] min-h-[2rem] rounded-full   flex items-center justify-center font-semibold ${
          currentStep >= 2
            ? "text-white bg-blue-500"
            : "text-blue-500 border border-blue-500"
        }`}
      >
        2
      </span>
      <p className=" capitalize text-xs hidden lg:block">home address</p>
      <span
        className={`w-full h-[1px]  ${
          currentStep > 2 ? "bg-blue-500" : "bg-N90"
        }`}
      ></span>
      <span
        className={` md:min-w-[2.5rem] min-w-[2rem] w-8 md:w-10 h-8 md:h-10 md:min-h-[2.5rem] min-h-[2rem] rounded-full   flex items-center justify-center font-semibold ${
          currentStep >= 3
            ? "text-white bg-blue-500"
            : "text-blue-500 border border-blue-500"
        }`}
      >
        3
      </span>
      <p className=" capitalize text-xs hidden lg:block">eligibility</p>
      <span
        className={`w-full h-[1px]  ${
          currentStep > 3 ? "bg-blue-500" : "bg-N90"
        }`}
      ></span>
      <span
        className={` md:min-w-[2.5rem] min-w-[2rem] w-8 md:w-10 h-8 md:h-10 md:min-h-[2.5rem] min-h-[2rem] rounded-full   flex items-center justify-center font-semibold ${
          currentStep >= 4
            ? "text-white bg-blue-500"
            : "text-blue-500 border border-blue-500"
        }`}
      >
        4
      </span>
      <p className=" capitalize text-xs hidden lg:block">identity</p>
      <span
        className={`w-full h-[1px]  ${
          currentStep > 4 ? "bg-blue-500" : "bg-N90"
        }`}
      ></span>
      <span
        className={` md:min-w-[2.5rem] min-w-[2rem] w-8 md:w-10 h-8 md:h-10 md:min-h-[2.5rem] min-h-[2rem] rounded-full   flex items-center justify-center font-semibold ${
          currentStep >= 5
            ? "text-white bg-blue-500"
            : "text-blue-500 border border-blue-500"
        }`}
      >
        5
      </span>
      <p className=" capitalize text-xs hidden lg:block">disclosure</p>
      <span
        className={`w-full h-[1px]  ${
          currentStep > 6 ? "bg-blue-500" : "bg-N90"
        }`}
      ></span>
      <span
        className={` md:min-w-[2.5rem] min-w-[2rem] w-8 md:w-10 h-8 md:h-10 md:min-h-[2.5rem] min-h-[2rem] rounded-full   flex items-center justify-center font-semibold ${
          currentStep >= 6
            ? "text-white bg-blue-500"
            : "text-blue-500 border border-blue-500"
        }`}
      >
        6
      </span>
      <p className=" capitalize text-xs hidden lg:block">confirmation</p>
    </div>
  );
};

export default StepperHeader;
