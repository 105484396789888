import React, { useContext, useEffect, useState } from "react";
import Button from "../../common/Button";
import Medicaid from "../../../assets/images/medicaid.jpg";
import nsl from "../../../assets/images/nsl.webp";
import income from "../../../assets/images/income.png";
import snap from "../../../assets/images/snap.jpg";
import section8 from "../../../assets/images/section-8.jpeg";
import ssi from "../../../assets/images/ssi.webp";
import va from "../../../assets/images/va.jpg";
import pell from "../../../assets/images/pell.jpg";
import wic from "../../../assets/images/wic.jpeg";
import Modal from "../../common/Modal";
import FileUpload from "./FileUpload";
import PellGrant from "./PellGrant";
import Income from "./Income";
const ProgramCard = ({ img, title, value, selected, handleClick }) => {
  const [uploadModal, setUploadModal] = useState(false);
  const [pellModal, setPellModal] = useState(false);
  const [incomeModal, setIncomeModal] = useState(false);
  const [note, setNote] = useState("");

  useEffect(() => {
    switch (value) {
      case "646faac1b6fea31d6c975a3a":
        setNote("Note: You will need the benefit letter with your name on it.");
        break;
      case "646facddb6fea31d6c975a4b":
        setNote("Note: you will need to enter your school name");
        break;
      case "646faafdb6fea31d6c975a3c":
      case "646facbdb6fea31d6c975a49":
        setNote("Note: You will need the benefit letter.");
        break;
      case "646fab9db6fea31d6c975a3e":
      case "646fabeeb6fea31d6c975a42":
      case "646fabc2b6fea31d6c975a40":
        setNote("Note: You will Need award letter from the current year.");
        break;
      case "646fac52b6fea31d6c975a46":
        setNote("Note: You will need to provide proof of income.");
        break;
      case "646fac35b6fea31d6c975a44":
        setNote(
          "Try Medicaid or SNAP first. You can also try parents information on Medicaid or SNAP. If that does not work, get a Letter from the school stating the free lunch. You could call the school or parent for this letter."
        );
        break;
      default:
        setNote("");
        break;
    }
  }, [value]);
  return (
    <>
      <Modal
        closeModal={(val) => setUploadModal(val)}
        isOpen={uploadModal}
        title="file upload"
      >
        <FileUpload
          type="Supporting"
          note={note}
          closeModal={(val) => setUploadModal(val)}
        />
      </Modal>
      <Modal
        closeModal={(val) => setPellModal(val)}
        isOpen={pellModal}
        title="federal pell grant"
      >
        <PellGrant closeModal={(val) => setPellModal(val)} />
      </Modal>
      <Modal
        closeModal={(val) => setIncomeModal(val)}
        isOpen={selected === "646fac52b6fea31d6c975a46" && incomeModal}
        title="federal poverty guidelines"
      >
        <Income closeModal={(val) => setIncomeModal(val)} />
      </Modal>
      <div
        className={`relative rounded shadow-md overflow-hidden bg-white cursor-pointer border-2 ${
          value === selected ? "border-blue-500 shadow-lg" : "border-N80"
        }`}
      >
        {value === selected && (
          <div className="absolute top-0 left-0 w-full h-full bg-blue-500/60 backdrop-blur-[2px] text-white text-center flex gap-4 items-center flex-col justify-center">
            <Button
              custom="bg-white text-blue-500"
              handleClick={() => {
                if (selected === "646fac52b6fea31d6c975a46") {
                  setIncomeModal(true);
                } else if (selected !== "646facddb6fea31d6c975a4b") {
                  setUploadModal(true);
                } else {
                  setPellModal(true);
                }
              }}
            >
              {selected === "646facddb6fea31d6c975a4b" && "enter school name"}
              {selected === "646fac52b6fea31d6c975a46" &&
                "qualify through income"}

              {selected !== "646facddb6fea31d6c975a4b" &&
                selected !== "646fac52b6fea31d6c975a46" &&
                "upload"}
            </Button>
            <p className="px-2">
              Although it is not mandatory, please upload any benefit letter
              that may assist enrollment process.
            </p>
          </div>
        )}
        {img === "646faafdb6fea31d6c975a3c" && (
          <img src={snap} alt={title} className="h-56 w-full object-cover" />
        )}
        {img === "646fac35b6fea31d6c975a44" && (
          <img src={nsl} alt={title} className="h-56 w-full object-cover" />
        )}
        {img === "646fab9db6fea31d6c975a3e" && (
          <img src={ssi} alt={title} className="h-56 w-full object-cover" />
        )}
        {img === "646faac1b6fea31d6c975a3a" && (
          <img
            src={Medicaid}
            alt={title}
            className="h-56 w-full object-cover"
          />
        )}
        {img === "646fabeeb6fea31d6c975a42" && (
          <img src={va} alt={title} className="h-56 w-full object-cover" />
        )}
        {img === "646fabc2b6fea31d6c975a40" && (
          <img
            src={section8}
            alt={title}
            className="h-56 w-full object-cover"
          />
        )}
        {img === "646facbdb6fea31d6c975a49" && (
          <img src={wic} alt={title} className="h-56 w-full object-cover" />
        )}
        {img === "646fac52b6fea31d6c975a46" && (
          <img src={income} alt={title} className="h-56 w-full object-cover" />
        )}
        {img === "646facddb6fea31d6c975a4b" && (
          <img src={pell} alt={title} className="h-56 w-full object-cover" />
        )}
        <div className="p-4 lg:p-8 text-center space-y-2">
          <h2 className="font-semibold text-lg text-N50 capitalize">{title}</h2>
          <Button
            handleClick={() => {
              if (value === "646fac52b6fea31d6c975a46") {
                setIncomeModal(true);
              }
              handleClick(value);
            }}
            custom="bg-blue-500 text-white w-1/2"
          >
            select
          </Button>
        </div>
      </div>
    </>
  );
};

export default ProgramCard;
