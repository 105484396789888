import React, { useContext, useState } from "react";
import Input from "../../common/Input";
import Button from "../../common/Button";
import { UserContext } from "../../../context/userContext";
import { useFormik } from "formik";
import * as Yup from "yup";

const SecondStep = ({ prevStep, nextStep }) => {
  const { user, setUser } = useContext(UserContext);

  const addressSchema = Yup.object().shape({
    addressStatus: Yup.string().required(),
    address1: Yup.string().required(),
    address2: Yup.string(),
  });

  const initialValues = {
    address1: "",
    address2: "",
    addressStatus: "Permanent",
  };

  const handleSubmit = (values) => {
    if (values) {
      setUser({
        ...user,
        address1: values.address1,
        address2: values.address2,
        addressStatus: values.addressStatus,
      });
      nextStep();
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: addressSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div className="space-y-4 lg:space-y-6">
      <div>
        <h3 className="font-semibold text-N40 capitalize mb-1">
          what is your home address?
        </h3>
        <p className="text-sm text-N50">
          The address where you will get service. Do not use P.O Box.
        </p>
      </div>
      <form className=" space-y-4 xl:space-y-6" onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          <Input
            label="address 1"
            required={true}
            name="address1"
            value={formik.values.address1}
            error={formik.touched.address1 && formik.errors.address1}
            handleChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Input
            label="address 2"
            name="address2"
            value={formik.values.address2}
            handleChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.address2 && formik.errors.address2}
          />
          <Input
            readOnly={true}
            value={user.zip}
            label="zip code"
            required={true}
            disabled={true}
            onChange={formik.handleChange}
          />

          <Input
            readOnly={true}
            value={user.city}
            label="city"
            required={true}
            disabled={true}
            onChange={formik.handleChange}
          />
          <Input
            readOnly={true}
            value={user.state}
            label="state"
            required={true}
            disabled={true}
            onChange={formik.handleChange}
          />
          <div className="space-y-2">
            <p>
              Is this a Temporary Address?{" "}
              <span className="text-red-500">*</span>
            </p>
            <div className="flex items-center gap-4 xl:gap-8 capitalize text-sm text-N50">
              <div className="flex items-center gap-2">
                <input
                  checked={formik.values.addressStatus === "Temporary"}
                  onChange={formik.handleChange}
                  value="Temporary"
                  name="addressStatus"
                  type="radio"
                  id="yes"
                />
                <label htmlFor="yes">yes</label>
              </div>
              <div className="flex items-center gap-2">
                <input
                  checked={formik.values.addressStatus === "Permanent"}
                  onChange={formik.handleChange}
                  value="Permanent"
                  name="addressStatus"
                  type="radio"
                  id="no"
                />
                <label htmlFor="no">no</label>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between gap-4">
          <Button handleClick={prevStep} custom="border border-N90 w-32">
            &lt; back
          </Button>
          <Button
            type="submit"
            handleClick={handleSubmit}
            custom="border border-blue-500 text-blue-500 w-36"
          >
            continue &gt;
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SecondStep;
