import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query GetUsers {
    users {
      firstName
      lastName
      middleName
      email
      phone
      zip
      state
      city
      school
      livingWithAdult
      dob
      ssn
      assistanceRecipient
      adultAssistanceReceptionStatus
      nationalVerifierApplicationId
      suffix
      address1
      address2
      addressStatus
      reach
      freePhone
      freeReplacementSIM
      phoneUpgrade
      tabletBundle
      unlimitedDataPlan
      supportingDocument {
        image
      }
      identityDocument {
        image
      }
      affiliate {
        email
        firstName
        lastName
      }
      program {
        _id
        name
      }
    }
  }
`;

export const GET_PROGRAMS = gql`
  query GetUsers {
    Programs {
      _id
      name
    }
  }
`;
export const GET_AFFILIATES = gql`
  query GetAffiliates {
    affiliates {
      _id
      email
      firstName
      lastName
    }
  }
`;
