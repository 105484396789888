import React from "react";

const ImageViewer = ({ url, closeModal }) => {
  return (
    <div className="p-4 flex items-center justify-center 2xl:p-8">
      {url.includes(".pdf") ? (
        <embed
          src={url}
          type="application/pdf"
          className="relative z-40 w-full min-h-[80vh]"
        />
      ) : (
        <img src={url} alt={url} className="relative z-40 max-h-[60vh]" />
      )}
    </div>
  );
};

export default ImageViewer;
