import React, { useContext } from "react";
import { UserContext } from "../../../context/userContext";
import Button from "../../common/Button";
import { toast } from "react-hot-toast";
import Toast from "../../common/Toast";
import { ADD_USERS } from "../../../apollo/mutations";
import Loader from "../../common/Loader";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
const Confirmation = ({ prevStep, nextStep }) => {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const [addUser, { data, loading, error }] = useMutation(ADD_USERS, {
    variables: {
      firstName: user.firstName,
      lastName: user.lastName,
      middleName: user.middleName,
      suffix: user.suffix,
      ssn: user.ssn,
      dob: user.dob,
      reach: user.reach,
      email: user.email,
      phone: user.phone,
      assistanceRecipient: user.assistanceRecipient,
      address1: user.address1,
      address2: user.address2,
      city: user.city,
      state: user.state,
      zip: user.zip,
      school: user.school,
      program: user.program,
      livingWithAdult: user.livingWithAdult,
      adultAssistanceReceptionStatus: user.adultAssistanceReceptionStatus,
      nationalVerifierApplicationId: user.nationalVerifierApplicationId,
      addressStatus: user.addressStatus,
      supportingDocument: user.SupportingDocument,
      identityDocument: user.identityDocument,
      affiliate: user.affiliate,
      optInSim: user.optInSim,
      optInForOtherPrograms: user.optInForOtherPrograms,
      financialBurden: user.financialBurden,
      freePhone: user.freePhone,
      freeReplacementSIM: user.freeReplacementSIM,
      phoneUpgrade: user.phoneUpgrade,
      tabletBundle: user.tabletBundle,
      unlimitedDataPlan: user.unlimitedDataPlan,
    },
    onCompleted: (data) => {
      toast.custom((t) => (
        <Toast type="success" msg="user added successfully" t={t} />
      ));

      navigate("/");
    },
    onError: (error) => {
      toast.custom((t) => <Toast type="error" msg={error.message} t={t} />);
    },
  });

  const handleSubmit = () => {
    addUser();
  };

  if (loading)
    return (
      <div className="relative w-full h-screen">
        <Loader />
      </div>
    );

  return (
    <div className="mx-auto py-8">
      <h1 className="text-3xl font-bold mb-4 text-blue-500">
        Confirmation Page
      </h1>
      <div className="bg-white shadow-md p-8">
        <h2 className="text-xl font-bold mb-4">Personal Information</h2>
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <p className="font-bold">First Name:</p>
            <p>{user.firstName}</p>
          </div>
          <div>
            <p className="font-bold">Last Name:</p>
            <p>{user.lastName}</p>
          </div>
          <div>
            <p className="font-bold">Middle Name:</p>
            <p>{user.middleName}</p>
          </div>
          {user.suffix && (
            <div>
              <p className="font-bold">Suffix:</p>
              <p>{user.suffix}</p>
            </div>
          )}
          <div>
            <p className="font-bold">SSN:</p>
            <p>{user.ssn}</p>
          </div>
          <div>
            <p className="font-bold">Date of Birth:</p>
            <p>{user.dob}</p>
          </div>
          <div>
            <p className="font-bold">Reach:</p>
            <p>{user.reach}</p>
          </div>

          <div>
            <p className="font-bold">Email:</p>
            <p>{user.email}</p>
          </div>
          <div>
            <p className="font-bold">Phone:</p>
            <p>{user.phone}</p>
          </div>
          <div>
            <p className="font-bold">Assistance Recipient:</p>
            <p>{user.assistanceRecipient}</p>
          </div>
          <div>
            <p className="font-bold">Address 1:</p>
            <p>{user.address1}</p>
          </div>
          {user.address2 && (
            <div>
              <p className="font-bold">Address 2:</p>
              <p>{user.address2}</p>
            </div>
          )}
          <div>
            <p className="font-bold">City:</p>
            <p>{user.city}</p>
          </div>
          <div>
            <p className="font-bold">State:</p>
            <p>{user.state}</p>
          </div>
          <div>
            <p className="font-bold">Zip Code:</p>
            <p>{user.zip}</p>
          </div>
          <div>
            <p className="font-bold">Address Status:</p>{" "}
            <p>{user.addressStatus}</p>
          </div>
          <div>
            <p className="font-bold">Living with Adult:</p>
            <p>{user.livingWithAdult ? "Yes" : "No"}</p>
          </div>
          <div>
            <p className="font-bold">Adult Assistance Reception Status:</p>
            <p>{user.adultAssistanceReceptionStatus ? "Yes" : "No"}</p>
          </div>
          {user.nationalVerifierApplicationId && (
            <div>
              <p className="font-bold">National Verifier Application ID:</p>
              <p>{user.nationalVerifierApplicationId}</p>
            </div>
          )}
          <div>
            <p className="font-bold">Program name:</p>
            <p>{user.programName}</p>
          </div>
          <div>
            <p className="font-bold">people in household:</p>
            <p>{user.houseHold}</p>
          </div>
          {user.school && (
            <div>
              <p className="font-bold">School:</p>
              <p>{user.school}</p>
            </div>
          )}
          <div>
            <p className="font-bold">Contact Consent:</p>
            <p>{user.contactConsent ? "Yes" : "No"}</p>
          </div>
          <div>
            <p className="font-bold">
              Affordable Connectivity Program Consent:
            </p>
            <p>{user.affordableConnectivityProgramConsent ? "Yes" : "No"}</p>
          </div>
          <div>
            <p className="font-bold">opt out of Sim:</p>
            <p>{user.optInSim ? "Yes" : "No"}</p>
          </div>
          <div>
            <p className="font-bold">hear about other programs:</p>
            <p>{user.optInForOtherPrograms ? "Yes" : "No"}</p>
          </div>
          <div>
            <p className="font-bold">File Upload:</p>
            <p>
              {user.SupportingDocument
                ? user.SupportingDocument
                : "No file uploaded"}
            </p>
          </div>
          <div>
            <p className="font-bold">Identity file Upload:</p>
            <p>
              {user.identityDocument
                ? user.identityDocument
                : "No file uploaded"}
            </p>
          </div>
        </div>
        <div className="flex justify-between w-full">
          <Button
            custom="border-blue-500 border text-blue-500"
            handleClick={prevStep}
          >
            Edit Information
          </Button>
          <Button
            custom="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            handleClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
