import * as Yup from "yup";
import { useFormik } from "formik";
import Button from "../../common/Button";

const FifthStep = ({ prevStep, nextStep }) => {
  const agreeSchema = Yup.object().shape({
    agree: Yup.boolean().isTrue("you must agree with the above agreement"),
  });

  const initialValues = { agree: false };

  const handleSubmit = (values) => {
    if (values) {
      nextStep();
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: agreeSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div className=" space-y-4 2xl:space-y-6">
      <div>
        <h1 className="capitalize font-semibold text-lg mb-1 text-blue-900">
          affordable connectivity program agreement
        </h1>
        <p className="text-sm text-N60 w-11/12">
          PLEASE CAREFULLY READ AND AGREE BY INITIALING ALL THE BOXES FOR THE
          FOLLOWING STATEMENTS. BY CLICKING THE BOXES BELOW, YOU AGREE TO E-SIGN
          THE STATEMENTS BELOW WITH YOUR INITIALS AND THAT THE STATEMENTS
          INITIALED ARE ENFORCEABLE.
        </p>
      </div>
      <p className="text-sm text-N20 w-full sm:w-4/5 lg:w-3/4">
        17. I (or my dependent or other person in my household) currently get
        benefits from the government program(s) listed on this form or my annual
        household income is 200% or less than the Federal Poverty Guidelines
        (the amount listed in the Federal Poverty Guidelines table on this
        form). Initial
      </p>
      <p className="text-sm text-N20 w-full sm:w-4/5 lg:w-3/4">
        18. I agree that if I move I will give my service provider my new
        address within 30 days. Initial
      </p>
      <p className="text-sm text-N20 w-full sm:w-4/5 lg:w-3/4">
        19. I understand that I have to tell my service provider within 30 days
        if I do not qualify for the ACP anymore, including:
      </p>
      <p className="text-sm text-N20 w-full sm:w-4/5 lg:w-3/4 ml-8 xl:ml-12">
        1. I, or the person in my household that qualifies, do not qualify
        through a government program or income anymore.
      </p>
      <p className="text-sm text-N20 w-full sm:w-4/5 lg:w-3/4 ml-8 xl:ml-12">
        2. Either I or someone in my household gets more than one ACP benefit.
        Initial
      </p>
      <p className="text-sm text-N20 w-full sm:w-4/5 lg:w-3/4">
        20. I know that my household can only get one ACP benefit and, to the
        best of my knowledge, my household is not getting more than one ACP
        benefit. I understand that I can only receive one connected device
        (desktop, laptop, or tablet) through the ACP, even if I switch ACP
        companies. Initial
      </p>
      <p className="text-sm text-N20 w-full sm:w-4/5 lg:w-3/4">
        21. I agree that all of the information I provide on this form may be
        collected, used, shared, and retained for the purposes of applying for
        and/or receiving the ACP benefit. I understand that if this information
        is not provided to the Program Administrator, I will not be able to get
        ACP benefits. If the laws of my state or Tribal government require it, I
        agree that the state or Tribal government may share information about my
        benefits for a qualifying program with the ACP Administrator. The
        information shared by the state or Tribal government will be used only
        to help find out if I can get an ACP benefit. Initial
      </p>
      <p className="text-sm text-N20 w-full sm:w-4/5 lg:w-3/4">
        22. For my household, I affirm and understand that the ACP is a federal
        government subsidy that reduces my broadband internet access service
        bill and at the conclusion of the program, my household will be subject
        to the company’s undiscounted general rates, terms, and conditions if my
        household continues to subscribe to the service. Initial
      </p>
      <p className="text-sm text-N20 w-full sm:w-4/5 lg:w-3/4">
        23. All the answers and agreements that I provided on this form are true
        and correct to the best of my knowledge. Initial
      </p>
      <p className="text-sm text-N20 w-full sm:w-4/5 lg:w-3/4">
        24. I know that willingly giving false or fraudulent information to get
        ACP benefits is punishable by law and can result in fines, jail time,
        de-enrollment, or being barred from the program. Initial
      </p>
      <p className="text-sm text-N20 w-full sm:w-4/5 lg:w-3/4">
        25. The ACP Administrator or my service provider may have to check
        whether I still qualify at any time. If I need to recertify my ACP
        benefit, I understand that I have to respond by the deadline or I will
        be removed from the Affordable Connectivity Program and my ACP benefit
        will stop. The certification below applies to all consumers and is
        required to process your application. Initial
      </p>
      <p className="text-sm text-N20 w-full sm:w-4/5 lg:w-3/4">
        26. I was truthful about whether or not I am a resident of Tribal lands,
        as defined in the “Your Information” section of this form.
      </p>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            id="agree"
            checked={formik.values.agree}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            name="agree"
          />
          <label htmlFor="agree" className="text-N10 capitalize font-medium">
            I agree
          </label>
          {formik.errors.agree && (
            <p className="text-red-400 text-xs capitalize">
              {formik.errors.agree}
            </p>
          )}
        </div>
        <div className="flex items-center justify-between gap-4 mt-4">
          <Button handleClick={prevStep} custom="border border-N90 w-32">
            &lt; back
          </Button>
          <Button
            type="submit"
            handleClick={handleSubmit}
            custom="border border-blue-500 text-blue-500 w-36"
          >
            continue &gt;
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FifthStep;
