import { gql } from "@apollo/client";

export const ADD_USERS = gql`
  mutation AddUser(
    $firstName: String!
    $lastName: String!
    $middleName: String
    $email: String!
    $phone: String!
    $zip: String!
    $state: String!
    $city: String!
    $school: String
    $livingWithAdult: Boolean!
    $dob: String!
    $ssn: String!
    $assistanceRecipient: AssistanceRecipient!
    $adultAssistanceReceptionStatus: Boolean!
    $nationalVerifierApplicationId: String
    $suffix: String
    $address1: String!
    $address2: String
    $addressStatus: AddressStatus!
    $reach: Reach!
    $supportingDocument: ID
    $identityDocument: ID
    $program: ID!
    $affiliate: ID
    $financialBurden: Boolean!
    $optInSim: Boolean!
    $optInForOtherPrograms: Boolean!
    $freePhone: Boolean!
    $freeReplacementSIM: Boolean!
    $phoneUpgrade: Boolean!
    $tabletBundle: Boolean!
    $unlimitedDataPlan: Boolean!
  ) {
    addUser(
      firstName: $firstName
      lastName: $lastName
      middleName: $middleName
      email: $email
      phone: $phone
      zip: $zip
      state: $state
      city: $city
      school: $school
      livingWithAdult: $livingWithAdult
      dob: $dob
      ssn: $ssn
      assistanceRecipient: $assistanceRecipient
      adultAssistanceReceptionStatus: $adultAssistanceReceptionStatus
      nationalVerifierApplicationId: $nationalVerifierApplicationId
      suffix: $suffix
      address1: $address1
      address2: $address2
      addressStatus: $addressStatus
      reach: $reach
      supportingDocument: $supportingDocument
      identityDocument: $identityDocument
      program: $program
      affiliate: $affiliate
      optInSim: $optInSim
      optInForOtherPrograms: $optInForOtherPrograms
      financialBurden: $financialBurden
      freePhone: $freePhone
      freeReplacementSIM: $freeReplacementSIM
      phoneUpgrade: $phoneUpgrade
      tabletBundle: $tabletBundle
      unlimitedDataPlan: $unlimitedDataPlan
    ) {
      _id
      firstName
      lastName
      middleName
    }
  }
`;
export const ADD_AFFILIATE = gql`
  mutation addAffiliates(
    $firstName: String!
    $lastName: String!
    $email: String!
  ) {
    addAffiliate(firstName: $firstName, lastName: $lastName, email: $email) {
      firstName
      _id
    }
  }
`;
export const LOGIN = gql`
  mutation loginAdmin($email: String!, $password: String!) {
    loginAdmin(email: $email, password: $password) {
      token
    }
  }
`;
