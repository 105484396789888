import React, { useState, useContext } from "react";
import Button from "../../common/Button";
import { UserContext } from "../../../context/userContext";
import { toast } from "react-hot-toast";
import Toast from "../../common/Toast";
import { useNavigate } from "react-router-dom";
const Questionaire = ({ nextStep, closeModal }) => {
  const { user, setUser } = useContext(UserContext);
  const [adultAssistanceReceptionStatus, setAdultAssistanceReceptionStatus] =
    useState(false);
  const [liveWithAdult, setLiveWithAdult] = useState(false);
  const [taxTogether, setTaxTogether] = useState(false);
  const navigate = useNavigate();
  const handleContinue = () => {
    if (liveWithAdult && adultAssistanceReceptionStatus && taxTogether) {
      toast.custom((t) => (
        <Toast
          type="error"
          msg="Sorry, you're not eligible for the program"
          t={t}
        />
      ));
      navigate("/");
      return;
    }
    if (!liveWithAdult) {
      setUser({
        ...user,
        adultAssistanceReceptionStatus: false,
        livingWithAdult: liveWithAdult,
        taxTogether: taxTogether,
      });
      nextStep();
      closeModal();
      return;
    }
    setUser({
      ...user,
      adultAssistanceReceptionStatus: adultAssistanceReceptionStatus,
      livingWithAdult: liveWithAdult,
      taxTogether,
    });
    nextStep();
    closeModal();
  };

  return (
    <div className="p-4 xl:p-8">
      <div className="space-y-4">
        <div className=" space-y-4">
          <h3 className="font-semibold capitalize">
            do you live with another adult?
          </h3>
          <div className="flex items-center gap-4 justify-between">
            <Button
              handleClick={() => setLiveWithAdult(true)}
              custom={`border w-28 flex items-center gap-2 justify-center ${
                liveWithAdult
                  ? "bg-blue-500 text-white border-blue-500"
                  : "text-N40 border-N60"
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              yes
            </Button>

            <Button
              handleClick={() => setLiveWithAdult(false)}
              custom={`border w-28 flex items-center gap-2 justify-center ${
                !liveWithAdult
                  ? "bg-blue-500 text-white border-blue-500"
                  : "text-N40 border-N70"
              }`}
            >
              no
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </Button>
          </div>
        </div>
        {liveWithAdult && (
          <div className="space-y-4">
            <h3 className="font-semibold capitalize">
              do they get the affordable connectivity program?
            </h3>
            <div className="flex items-center gap-4 justify-between">
              <Button
                handleClick={() => setAdultAssistanceReceptionStatus(true)}
                custom={`border w-28 flex items-center gap-2 justify-center ${
                  adultAssistanceReceptionStatus
                    ? "bg-blue-500 text-white border-blue-500"
                    : "text-N40 border-N60"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                yes
              </Button>

              <Button
                handleClick={() => setAdultAssistanceReceptionStatus(false)}
                custom={`border w-28 flex items-center gap-2 justify-center ${
                  !adultAssistanceReceptionStatus
                    ? "bg-blue-500 text-white border-blue-500"
                    : "text-N40 border-N70"
                }`}
              >
                no
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </Button>
            </div>
          </div>
        )}

        {liveWithAdult && adultAssistanceReceptionStatus && (
          <div className="space-y-4">
            <h3 className="font-semibold capitalize">
              do you file your taxes together?
            </h3>
            <div className="flex items-center gap-4 justify-between">
              <Button
                handleClick={() => setTaxTogether(true)}
                custom={`border w-28 flex items-center gap-2 justify-center ${
                  taxTogether
                    ? "bg-blue-500 text-white border-blue-500"
                    : "text-N40 border-N60"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                yes
              </Button>

              <Button
                handleClick={() => setTaxTogether(false)}
                custom={`border w-28 flex items-center gap-2 justify-center ${
                  !taxTogether
                    ? "bg-blue-500 text-white border-blue-500"
                    : "text-N40 border-N70"
                }`}
              >
                no
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </Button>
            </div>
          </div>
        )}

        <Button
          handleClick={handleContinue}
          custom="bg-blue-500 text-white w-full"
        >
          continue
        </Button>
      </div>
    </div>
  );
};

export default Questionaire;
