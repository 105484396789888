import React, { useState } from "react";
import StepperHeader from "../components/layout/register/StepperHeader";
import FirstStep from "../components/layout/register/FirstStep";
import Modal from "../components/common/Modal";
import SecondStep from "../components/layout/register/SecondStep";
import Questionaire from "../components/layout/register/Questionaire";
import ThirdStep from "../components/layout/register/ThirdStep";
import PellGrant from "../components/layout/register/PellGrant";
import Confirmation from "../components/layout/register/Confirmation";
import PurchasedItem from "../components/layout/register/PurchasedItem";
import FourthStep from "../components/layout/register/FourthStep";
import FifthStep from "../components/layout/register/FifthStep";
import Payment from "../components/layout/Payment";
const Register = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [newUserId, setNewUserId] = useState(null);
  const [questionaireModal, setQuestionaireModal] = useState(false);
  const [pellModal, setPellModal] = useState(false);
  const nextStep = () => {
    if (currentStep !== 8) {
      if (currentStep === 2) {
        setQuestionaireModal(true);
        return;
      }
      setCurrentStep((prev) => prev + 1);
    }
  };
  const prevStep = () => {
    if (currentStep === 1) return;
    setCurrentStep((prev) => prev - 1);
  };
  return (
    <div className=" space-y-8 bg-blue-50 h-body">
      <Modal
        closeModal={(val) => setQuestionaireModal(val)}
        isOpen={questionaireModal}
        title="additional information"
      >
        <Questionaire
          closeModal={(val) => setQuestionaireModal(val)}
          nextStep={() => setCurrentStep(currentStep + 1)}
        />
      </Modal>
      <Modal
        closeModal={(val) => setPellModal(val)}
        isOpen={pellModal}
        title="beneficiary information"
      >
        <PellGrant />
      </Modal>

      <StepperHeader currentStep={currentStep} />
      <div className="w-11/12 mx-auto bg-white p-4 xl:p-6 2xl:p-8 space-y-6">
        {currentStep === 1 && <FirstStep nextStep={nextStep} />}
        {currentStep === 2 && (
          <SecondStep prevStep={prevStep} nextStep={nextStep} />
        )}
        {currentStep === 3 && (
          <ThirdStep prevStep={prevStep} nextStep={nextStep} />
        )}
        {currentStep === 4 && (
          <FourthStep prevStep={prevStep} nextStep={nextStep} />
        )}
        {currentStep === 5 && (
          <FifthStep prevStep={prevStep} nextStep={nextStep} />
        )}
        {currentStep === 6 && (
          <PurchasedItem
            setNewUserId={setNewUserId}
            setCurrentStep={setCurrentStep}
            prevStep={prevStep}
            nextStep={nextStep}
          />
        )}
        {currentStep === 7 && (
          <Confirmation prevStep={prevStep} nextStep={nextStep} />
        )}
        {currentStep === 8 && (
          <Payment newUserId={newUserId} prevStep={prevStep} />
        )}
      </div>
    </div>
  );
};

export default Register;
