import React, { useContext, useState } from "react";
import Input from "../../common/Input";
import Dropdown from "../../common/Dropdown";
import { UserContext } from "../../../context/userContext";
import { userSchema } from "../../../utils/userSchema";
import { useFormik } from "formik";
import Button from "../../common/Button";
import { useNavigate } from "react-router-dom";
const FirstStep = ({ nextStep }) => {
  const { user, setUser } = useContext(UserContext);
  const [suffix, setSuffix] = useState("");
  const navigate = useNavigate();

  const initialValues = {
    firstName: user.firstName,
    middleName: user.middleName,
    lastName: user.lastName,
    email: user.email,
    ssn: user.ssn,
    dob: user.dob,
    reach: user.reach,
    phone: user.phone,
    assistanceRecipient: user.assistanceRecipient,
    contactConsent: user.contactConsent,
    affordableConnectivityProgramConsent:
      user.affordableConnectivityProgramConsent,
  };

  const handleSubmit = (value) => {
    if (value) {
      setUser((prev) => ({
        ...prev,
        firstName: value?.firstName,
        middleName: value?.middleName,
        lastName: value?.lastName,
        suffix: suffix,
        email: value?.email,
        ssn: value?.ssn,
        dob: value?.dob,
        reach: value?.reach,
        phone: value?.phone,
        assistanceRecipient: value?.assistanceRecipient,
        contactConsent: value?.contactConsent,
        affordableConnectivityProgramConsent:
          value?.affordableConnectivityProgramConsent,
      }));
      nextStep();
      return;
    }
  };

  const handleNumberChange = (event) => {
    const { value } = event.target;
    const numericValue = value.replace(/\D/g, "").substring(0, 4); // remove non-numeric characters and limit to 4 digits
    formik.setFieldValue("ssn", numericValue); // update formik state with the numeric value
  };

  const formik = useFormik({
    initialValues,
    validationSchema: userSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div className="space-y-4 lg:space-y-6">
      <div>
        <h1 className="font-semibold capitalize text-N30">
          what is your name?
        </h1>
        <p className="text-sm text-N50">
          The name you use on official documents like your Social Security Card
          State ID. Not a nickname
        </p>
      </div>
      <p className="text-xs text-N50 text-right capitalize">
        <span className="text-red-500">*</span> mandatory fields
      </p>

      <form onSubmit={formik.handleSubmit} className="space-y-4 lg:space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          <div className="relative flex gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="blue"
              className="w-5 h-5 peer cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              />
            </svg>

            <div className="absolute peer-hover:opacity-100 peer-hover:visible bottom-full left-0 mb-2 p-2 bg-gray-50 text-N50 rounded-sm shadow opacity-0 invisible transition-opacity duration-300 text-xs capitalize border-b-2 border-blue-500">
              Make sure your first name is written correctly.
            </div>
            <Input
              label="first name"
              required={true}
              name="firstName"
              value={formik.values.firstName}
              error={formik.touched.firstName && formik.errors.firstName}
              onBlur={formik.handleBlur}
              handleChange={formik.handleChange}
            />
          </div>
          <Input
            label="middle name"
            name="middleName"
            value={formik.values.middleName}
            handleChange={formik.handleChange}
          />
          <div className="relative flex gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="blue"
              className="w-5 h-5 peer cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              />
            </svg>

            <div className="absolute peer-hover:opacity-100 peer-hover:visible bottom-full left-0 mb-2 p-2 bg-gray-50 text-N50 rounded-sm shadow opacity-0 invisible transition-opacity duration-300 text-xs capitalize border-b-2 border-blue-500">
              Make sure your last name is written correctly.
            </div>
            <Input
              label="last name"
              required={true}
              name="lastName"
              value={formik.values.lastName}
              error={formik.touched.lastName && formik.errors.lastName}
              onBlur={formik.handleBlur}
              handleChange={formik.handleChange}
            />
          </div>

          <Dropdown
            label="suffix"
            data={[
              { name: "sr" },
              { name: "jr" },
              { name: "III" },
              { name: "II" },
            ]}
            onChange={setSuffix}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          <Input
            label="last 4 digits (SSN)"
            required={true}
            name="ssn"
            value={formik.values.ssn}
            error={formik.touched.ssn && formik.errors.ssn}
            handleChange={handleNumberChange}
            onBlur={formik.handleBlur}
          />
          <Input
            label="date of birth"
            required={true}
            type="date"
            name="dob"
            value={formik.values.dob}
            error={formik.touched.dob && formik.errors.dob}
            onBlur={formik.handleBlur}
            handleChange={formik.handleChange}
          />
        </div>
        <div className="grid gap-4 grid-cols-1 lg:grid-cols-2">
          <div className=" space-y-2">
            <h3 className="font-semibold text-N40">
              What is the best way to reach you?{" "}
              <span className="text-red-500">*</span>
            </h3>
            <div className="flex items-center gap-4 xl:gap-8 capitalize text-sm text-N50">
              <div className="flex items-center gap-2">
                <input
                  type="radio"
                  checked={formik.values.reach === "Email"}
                  name="reach"
                  onChange={formik.handleChange}
                  id="email"
                  value="Email"
                />
                <label htmlFor="email">email</label>
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="radio"
                  name="reach"
                  checked={formik.values.reach === "Phone"}
                  onChange={formik.handleChange}
                  id="phone"
                  value="Phone"
                />
                <label htmlFor="phone">phone</label>
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="radio"
                  name="reach"
                  checked={formik.values.reach === "Text"}
                  onChange={formik.handleChange}
                  id="text"
                  value="Text"
                />
                <label htmlFor="text">text-message</label>
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="radio"
                  name="reach"
                  checked={formik.values.reach === "Mail"}
                  onChange={formik.handleChange}
                  value="Mail"
                  id="mail"
                />
                <label htmlFor="mail">mail</label>
              </div>
            </div>
          </div>
          <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
            <Input
              label="email (Recommended)"
              name="email"
              value={formik.values.email}
              error={formik.touched.email && formik.errors.email}
              onBlur={formik.handleBlur}
              handleChange={formik.handleChange}
            />
            <Input
              label="contact number"
              name="phone"
              value={formik.values.phone}
              error={formik.touched.phone && formik.errors.phone}
              onBlur={formik.handleBlur}
              handleChange={formik.handleChange}
            />
          </div>
        </div>
        <div className="flex items-start sm:flex-row flex-col gap-2">
          <input
            type="checkbox"
            id="agree"
            checked={formik.values.contactConsent}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            name="contactConsent"
            className="mt-1"
          />
          <div>
            <p className="text-sm text-N50 lg:w-3/4">
              By checking this box and your signature below, you are
              authorizing, SWA Connect or its representatives to use an
              automated system to deliver telemarketing calls to the phone
              number you provide above using an automatic telephone dialing
              system, and/or an artificial or pre-recorded voice or text
              message. Consenting to this agreement is not a required condition
              of purchasing any goods or services.
            </p>
            {formik.errors.contactConsent && formik.touched.contactConsent && (
              <p className="text-red-500 text-[10px] capitalize mt-1">
                {formik.errors.contactConsent}
              </p>
            )}
          </div>
        </div>
        <div className=" space-y-2 md:w-3/4 lg:w-1/2">
          <h3 className="font-semibold text-N40">
            Who recieves government assistance?(SNAP, Medicaid, Pell Grant...)?{" "}
            <span className="text-red-500">*</span>
          </h3>
          <div className="flex items-center gap-4 xl:gap-8 capitalize text-sm text-N50">
            <div className="flex items-center gap-2">
              <input
                type="radio"
                id="myself"
                checked={formik.values.assistanceRecipient === "Myself"}
                name="assistanceRecipient"
                onChange={formik.handleChange}
                value="Myself"
              />
              <label htmlFor="myself">myself</label>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="radio"
                id="somebody"
                checked={formik.values.assistanceRecipient === "SomeoneElse"}
                name="assistanceRecipient"
                onChange={formik.handleChange}
                value="SomeoneElse"
              />
              <label htmlFor="somebody">somebody else in houselhold</label>
            </div>
          </div>
        </div>
        <div className="space-y-2">
          <h3 className="font-semibold sm:text-lg text-N30 capitalize">
            affordable connectivity program (ACP) consent
          </h3>
          <div className="flex items-start sm:flex-row flex-col gap-2">
            <input
              type="checkbox"
              name="affordableConnectivityProgramConsent"
              onChange={formik.handleChange}
              checked={formik.values.affordableConnectivityProgramConsent}
              onBlur={formik.handleBlur}
              id="agree"
              className="mt-1"
            />
            <div>
              <p className="text-sm text-N50 lg:w-3/4">
                By continuing with your application you are choosing to
                participate in the Affordable Connectivity Program (ACP). This
                offer provides UNLIMITED Data, Talk, and Text. Once the benefits
                are available, they will be automatically added to your account.
                This program will end after the Public Health Emergency no
                longer exists, or as deemed by the FCC. Once the program is
                over, your plan will revert back to the standard Lifeline offer.
                You may also choose to keep this UNLIMITED plan but will be
                subject to the standard rates. We will notify you when there is
                a change to the program. As a participant, you may transfer the
                ACP Program benefit to another provider at any time. This is
                limited to one per household, and is subject to FCC approval.
              </p>
              {formik.errors.affordableConnectivityProgramConsent &&
                formik.touched.affordableConnectivityProgramConsent && (
                  <p className="text-red-500 text-[10px] capitalize mt-1">
                    {formik.errors.affordableConnectivityProgramConsent}
                  </p>
                )}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between gap-4">
          <Button
            handleClick={() => navigate("/")}
            custom="border border-N90 w-32"
          >
            &lt; back
          </Button>
          <Button
            type="submit"
            handleClick={handleSubmit}
            custom="border border-blue-500 text-blue-500 w-36"
          >
            continue &gt;
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FirstStep;
