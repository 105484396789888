import * as Yup from "yup";
import Input from "../components/common/Input";
import Button from "../components/common/Button";
import mapImage from "../assets/images/usa.jpg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import { UserContext } from "../context/userContext";
import { useContext, useEffect, useState } from "react";
import Toast from "../components/common/Toast";
import Loader from "../components/common/Loader";
import { toast } from "react-hot-toast";

const ZIPCODE_API = "https://api.zippopotam.us/us";
const Home = () => {
  const [searchParams] = useSearchParams();

  const { user, setUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const userSchema = Yup.object().shape({
    zipCode: Yup.string().required("zip code is required").min(5),
  });

  useEffect(() => {
    setUser({
      ...user,
      _id: new Date().getTime(),
      firstName: "",
      lastName: "",
      middleName: "",
      suffix: "",
      ssn: "",
      dob: "",
      reach: "Phone",
      email: "",
      phone: "",
      assistanceRecipient: "Myself",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      addressStatus: "",
      livingWithAdult: true,
      adultAssistanceReceptionStatus: true,
      nationalVerifierApplicationId: "",
      program: null,
      programName: "",
      school: "",
      contactConsent: false,
      affordableConnectivityProgramConsent: false,
      SupportingDocument: "",
      identityDocument: "",
      optInSim: true,
      optInForOtherPrograms: true,
      houseHold: 1,
      validIncome: true,
      affiliate: searchParams.get("affiliate"),
      financialBurden: false,
      freePhone: false,
      freeReplacementSIM: false,
      phoneUpgrade: false,
      tabletBundle: false,
      unlimitedDataPlan: false,
    });
    localStorage.removeItem("user");
  }, []);

  const fetchData = async (zipcode) => {
    try {
      setIsLoading(true);
      const response = await fetch(`${ZIPCODE_API}/${zipcode}`);
      if (!response.ok && response.status === 404) {
        setIsLoading(false);
        return toast.custom((t) => (
          <Toast type="error" msg="area not covered. check later" t={t} />
        ));
      }
      const data = await response.json();
      setIsLoading(false);
      setUser({
        ...user,
        zip: data["post code"],
        city: data.places[0]["place name"],
        state: data.places[0].state,
      });
      navigate("/register");
    } catch (error) {
      setIsLoading(false);
      return <Toast type="error" msg={error} t={5} />;
    }
  };

  const initialValues = { zipCode: "" };

  const handleSubmit = (values) => {
    if (values) {
      fetchData(values.zipCode);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: userSchema,
    onSubmit: handleSubmit,
  });
  return (
    <div className="h-body bg-blue-50 flex items-center justify-center">
      {isLoading && <Loader />}
      <div className="bg-white rounded-sm shadow-md p-4 w-11/12 sm:w-4/5 md:w-3/5 lg:w-2/5 xl:w-1/3 2xl:w-[30%]  space-y-8 mx-auto">
        <img src={mapImage} alt="map" className="rounded-sm" />
        <form onSubmit={formik.handleSubmit} className="space-y-3">
          <h1 className="text-center text-blue-900 capitalize font-semibold">
            enter the zip code to start the process
          </h1>
          <Input
            label="zip code"
            name="zipCode"
            value={formik.values.zipCode}
            error={formik.errors.zipCode}
            handleChange={formik.handleChange}
          />
          <Button
            type="submit"
            handleClick={handleSubmit}
            custom="bg-blue-500 w-full text-white py-3"
          >
            start
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Home;
