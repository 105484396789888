import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import Loader from "../common/Loader";

function Payment({ newUserId }) {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const url = `${process.env.REACT_APP_SERVER_URL}`
  useEffect(() => {
    fetch(url+"/config").then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetch(url+"/create-payment-intent", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: newUserId,
      }),
    }).then(async (result) => {
      var { clientSecret } = await result.json();
      setClientSecret(clientSecret);
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      <h1 className="text-2xl font-semibold text-blue-500">Checkout</h1>
      <div className="sm:w-11/12 lg:w-1/2 2xl:w-1/3 xl:w-2/5 space-y-4">
        {/* <div className="flex items-center justify-between capitalize">
          <h3 className="text-N20 font-semibold">price</h3>
          <p className="text-N70">$11</p>
        </div> */}
        {isLoading && <Loader />}
        {clientSecret && stripePromise && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm></CheckoutForm>
          </Elements>
        )}
      </div>
    </>
  );
}

export default Payment;
