import * as Yup from "yup";
import { useFormik } from "formik";
import Button from "../../common/Button";
import { UserContext } from "../../../context/userContext";
import { useContext, useRef } from "react";
import { toast } from "react-hot-toast";
import Toast from "../../common/Toast";
const FileUpload = ({ closeModal, note, type }) => {
  const { user, setUser } = useContext(UserContext);
  const fileRef = useRef(null);
  const userSchema = Yup.object().shape({
    SupportingDocument: Yup.mixed()
      .required("file is required")
      .test("is-file-too-big", "File exceeds 5MB", () => {
        let valid = true;
        const files = fileRef?.current?.files;
        if (files) {
          const fileArr = Array.from(files);
          fileArr.forEach((file) => {
            const size = file.size / 1024 / 1024;
            if (size > 5) {
              valid = false;
            }
          });
        }
        return valid;
      })
      .test(
        "is-file-type-valid",
        "Only PNG, JPG, JPEG, WEBP, PDF files are allowed",
        (value) => {
          let valid = true;
          const files = fileRef?.current?.files;
          if (files) {
            const fileArr = Array.from(files);
            fileArr.forEach((file) => {
              const fileType = file.type.split("/")[1].toLowerCase();
              if (!["png", "jpg", "jpeg", "webp", "pdf"].includes(fileType)) {
                valid = false;
              }
            });
          }
          return valid;
        }
      ),
  });
  const initialValues = { SupportingDocument: "" };

  const handleFileUpload = async (file) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/upload`;
    const formData = new FormData();

    formData.append("document", file);
    formData.append("type", type);
    const options = {
      method: "POST",
      body: formData,
    };
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      toast.custom((t) => <Toast type="success" msg={data.message} t={t} />);
      setUser({ ...user, SupportingDocument: data.data._id });
      closeModal(false);
    } catch (error) {
      toast.custom((t) => <Toast type="error" msg={error.message} t={t} />);
    }
  };

  const handleSubmit = (values) => {
    if (values) {
      handleFileUpload(fileRef.current.files[0]);
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema: userSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div className="extraOutline p-4 bg-white w-full bg-whtie m-auto rounded-lg">
      <p className="text-center capitalize text-sm text-N50 mb-4">{note}</p>
      <form
        onSubmit={formik.handleSubmit}
        className="file_upload p-5 relative border-gray-300 rounded-lg"
      >
        <svg
          className="text-blue-500 w-24 mx-auto mb-4"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
          />
        </svg>
        <div className="flex flex-col gap-2 w-max mx-auto text-center items-center">
          <label>
            <input
              className="text-sm cursor-pointer w-full mb-4 text-center mx-auto"
              type="file"
              name="SupportingDocument"
              id="SupportingDocument"
              ref={fileRef}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            {formik.touched.SupportingDocument &&
              formik.errors.SupportingDocument && (
                <p className="text-red-500 text-[10px] capitalize my-1">
                  {formik.errors.SupportingDocument}
                </p>
              )}
          </label>
        </div>
        <div className="flex justify-center">
          <Button
            type="submit"
            handleClick={handleSubmit}
            custom="bg-blue-500 text-white w-36"
          >
            continue
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FileUpload;
