import React, { useState } from "react";

const Dropdown = ({ label, id, data, onChange, error }) => {
  const [showList, setShowList] = useState(false);
  const [selected, setSelected] = useState(label);

  const handleChange = (name) => {
    setSelected(name);
    onChange(name);
  };

  return (
    <>
      <button
        onClick={() => setShowList((prev) => !prev)}
        onBlur={() => setShowList(false)}
        id={id}
        data-dropdown-toggle="dropdown"
        className="text-N40 bg-white border border-gray-300 focus:outline-none rounded-sm text-sm px-4 py-3 text-center flex items-center justify-between relative gap-2 capitalize group"
        type="button"
      >
        {selected}
        <svg
          className="w-4 h-4 ml-2"
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
        <div
          id="dropdown"
          className={`z-[1000]  absolute top-full left-0 bg-white py-2 rounded shadow w-full ${
            showList ? " visible opacity-100" : "invisible opacity-0"
          }`}
        >
          <ul
            className=" text-sm text-left max-h-[10rem] overflow-y-auto"
            aria-labelledby="dropdownDefaultButton"
          >
            {data.map(({ name }, idx) => {
              return (
                <li key={idx} onClick={() => handleChange(name)}>
                  <p className="block border-b border-b-N99/40 px-4 py-1.5">
                    {name}
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
        {error && (
          <p className="text-red-500 text-[12px] capitalize absolute top-full">
            this is a required field
          </p>
        )}
      </button>
    </>
  );
};

export default Dropdown;
