import * as Yup from "yup";

export const userSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  middleName: Yup.string(),
  ssn: Yup.string()
    .matches(/^\d{4}$/, "Must be a number with exactly 4 digits")
    .transform((value) =>
      value ? value.replace(/\D/g, "").substring(0, 4) : value
    )
    .required("Required"),
  dob: Yup.string().required("Date of birth is required"),
  reach: Yup.string().required("Reach is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string().required("Phone number is required"),
  assistanceRecipient: Yup.string().required(),
  contactConsent: Yup.boolean().isTrue("You must agree to contact consent"),
  affordableConnectivityProgramConsent: Yup.boolean().isTrue(
    "You must agree to affordable connectivity program consent"
  ),
});
