import React, { useContext, useState } from "react";
import Button from "../../common/Button";
import { UserContext } from "../../../context/userContext";
import { ADD_USERS } from "../../../apollo/mutations";
import { useMutation } from "@apollo/client";
import { toast } from "react-hot-toast";
import Toast from "../../common/Toast";
const PurchasedItem = ({
  prevStep,
  nextStep,
  setCurrentStep,
  setNewUserId,
}) => {
  const { user, setUser } = useContext(UserContext);
  const [selectedItems, setSelectedItems] = useState({
    freeReplacementSIM: user.freeReplacementSIM || false,
    freePhone: user.freePhone || false,
    unlimitedDataPlan: user.unlimitedDataPlan || false,
    phoneUpgrade: user.phoneUpgrade || false,
    tabletBundle: user.tabletBundle || false,
  });
  const [addUser, { data, loading, error }] = useMutation(ADD_USERS, {
    variables: {
      firstName: user.firstName,
      lastName: user.lastName,
      middleName: user.middleName,
      suffix: user.suffix,
      ssn: user.ssn,
      dob: user.dob,
      reach: user.reach,
      email: user.email,
      phone: user.phone,
      assistanceRecipient: user.assistanceRecipient,
      address1: user.address1,
      address2: user.address2,
      city: user.city,
      state: user.state,
      zip: user.zip,
      school: user.school,
      program: user.program,
      livingWithAdult: user.livingWithAdult,
      adultAssistanceReceptionStatus: user.adultAssistanceReceptionStatus,
      nationalVerifierApplicationId: user.nationalVerifierApplicationId,
      addressStatus: user.addressStatus,
      supportingDocument: user.SupportingDocument,
      identityDocument: user.identityDocument,
      affiliate: user.affiliate,
      optInSim: user.optInSim,
      optInForOtherPrograms: user.optInForOtherPrograms,
      financialBurden: user.financialBurden,
      freePhone: selectedItems.freePhone,
      freeReplacementSIM: selectedItems.freeReplacementSIM,
      phoneUpgrade: selectedItems.phoneUpgrade,
      tabletBundle: selectedItems.tabletBundle,
      unlimitedDataPlan: selectedItems.unlimitedDataPlan,
    },

    onError: (error) => {
      toast.custom((t) => <Toast type="error" msg={error.message} t={t} />);
    },
  });

  const handleChange = (e) => {
    setSelectedItems({
      ...selectedItems,
      [e.target.id]: e.target.checked,
    });
  };

  const handleSubmit = (e) => {
    if (
      selectedItems.phoneUpgrade ||
      selectedItems.tabletBundle ||
      selectedItems.unlimitedDataPlan
    ) {
      addUser({
        onCompleted: (data) => {
          setNewUserId(data.addUser._id);
          setCurrentStep(8);
        },
      });
      return;
    }
    setUser({
      ...user,
      ...selectedItems,
    });
    nextStep();
  };

  return (
    <div className="space-y-4 lg:space-y-6 2xl:space-y-10">
      <div>
        <h1 className="mb-1 font-bold text-N20">Services Provided</h1>
        <p className="text-sm text-N50">
          Selecting multiple services is allowed.
        </p>
      </div>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
        <div className="p-4 rounded-sm border">
          <div className="flex items-center gap-3 mb-2">
            <input
              onChange={handleChange}
              id="freeReplacementSIM"
              type="checkbox"
              checked={selectedItems.freeReplacementSIM}
            />
            <label htmlFor="freeReplacementSIM" className="font-semibold">
              Free Replacement SIM
            </label>
          </div>
          <p className="text-N40 text-sm">
            Recieve a complimentary SIM card that comes with 1 year of internet
            access. Stay connected without the extra costs! Recieve a phone at
            no cost to you! Comes with a limited data plan to get you started.
          </p>
        </div>
        <div className="p-4 rounded-sm border">
          <div className="flex items-center gap-3 mb-2">
            <input
              checked={selectedItems.freePhone}
              onChange={handleChange}
              id="freePhone"
              type="checkbox"
            />
            <label htmlFor="freePhone" className="font-semibold">
              Free Phone
            </label>
          </div>
          <p className="text-N40 text-sm">
            Recieve a phone at no cost to you! Comes with a limited data plan to
            get you started.
          </p>
        </div>
        <div className="p-4 rounded-sm border">
          <div className="flex items-center gap-3 mb-2">
            <input
              checked={selectedItems.unlimitedDataPlan}
              onChange={handleChange}
              id="unlimitedDataPlan"
              type="checkbox"
            />
            <label htmlFor="unlimitedDataPlan" className="font-semibold">
              <span className="text-blue-500">$10</span> Unlimited Data Plan
            </label>
          </div>
          <p className="text-N40 text-sm">
            Only $10! Enjoy limitless browsing, streaming, and more with our
            unlimited data plan.
          </p>
        </div>
        <div className="p-4 rounded-sm border">
          <div className="flex items-center gap-3 mb-2">
            <input
              checked={selectedItems.phoneUpgrade}
              onChange={handleChange}
              id="phoneUpgrade"
              type="checkbox"
            />
            <label htmlFor="phoneUpgrade" className="font-semibold">
              <span className="text-blue-500">$10</span> Phone Upgrade
            </label>
          </div>
          <p className="text-N40 text-sm">
            Enhance your experience for just $10! Upgrade to a newer model and
            enjoy improved features and performance.
          </p>
        </div>
        <div className="p-4 rounded-sm border">
          <div className="flex items-center gap-3 mb-2">
            <input
              checked={selectedItems.tabletBundle}
              onChange={handleChange}
              id="tabletBundle"
              type="checkbox"
            />
            <label htmlFor="tabletBundle" className="font-semibold">
              <span className="text-blue-500">$11</span> Tablet Bundle
            </label>
          </div>
          <p className="text-N40 text-sm">
            Get your latest tablet, bundled with 1 year of internet data. The
            perfect pairing for those on the go.
          </p>
        </div>
      </div>
      <p className="text-sm text-right text-N50">
        *Charges will only be applied after the customer recieves approval from
        the Lifeline programs
      </p>
      <div className="flex items-center justify-between gap-4 mt-4">
        <Button handleClick={prevStep} custom="border border-N90 w-32">
          &lt; back
        </Button>
        <Button
          type="submit"
          handleClick={handleSubmit}
          custom="border border-blue-500 text-blue-500 w-36"
        >
          continue &gt;
        </Button>
      </div>
    </div>
  );
};

export default PurchasedItem;
