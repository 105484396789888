import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Input from "../components/common/Input";
import Button from "../components/common/Button";
import { toast } from "react-hot-toast";
import Toast from "../components/common/Toast";
import Loader from "../components/common/Loader";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/userContext";
import { useMutation } from "@apollo/client";
import { LOGIN } from "../apollo/mutations";
const Login = () => {
  const { user, setUser } = useContext(UserContext);
  const [login, { data, error, loading }] = useMutation(LOGIN);
  const navigate = useNavigate();

  const loginSchema = Yup.object().shape({
    email: Yup.string().required("email is required").email(),
    password: Yup.string().required("password is required"),
  });

  const initialValues = { email: "", password: "" };

  const handleSubmit = (values) => {
    if (values) {
      login({
        variables: {
          email: values.email,
          password: values.password,
        },
      })
        .then((res) => {
          localStorage.setItem("token", res.data.loginAdmin.token);
          navigate("/admin");
        })
        .catch((err) => {
          toast.custom((t) => <Toast type="error" msg={err.message} t={t} />);
        });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/admin");
    }
  }, []);

  return (
    <>
      {loading && <Loader />}
      <div className="h-body bg-blue-50 flex items-center justify-center">
        <div className="bg-white rounded-sm shadow-md p-4 2xl:p-8 w-11/12 sm:w-4/5 md:w-3/5 lg:w-2/5 xl:w-1/3 2xl:w-1/4 space-y-8 mx-auto">
          <form className="space-y-4" onSubmit={formik.handleSubmit}>
            <h1 className="text-xl capitalize font-semibold text-center text-blue-900">
              sign in to your account
            </h1>
            <Input
              label="email"
              name="email"
              value={formik.values.email}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && formik.errors.email}
            />
            <Input
              label="password"
              type="password"
              name="password"
              value={formik.values.password}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && formik.errors.password}
            />
            <Button
              type="submit"
              custom="bg-blue-500 text-white w-full"
              handleClick={handleSubmit}
            >
              sign in
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
