import React from "react";
import logo from "../../assets/images/logo.jpg";
const Navbar = () => {
  return (
    <div className="h-20 bg-white shadow-md w-full flex items-center justify-end">
      <div className="sm:w-4/5 mx-auto items-center flex justify-between">
        {/* <img src={logo} className="w-20 sm:w-24" /> */}
        <div className=" space-y-1">
          <a href="mailto:Community.impact.wireless@gmail.com">Community.impact.wireless@gmail.com</a>
          <p className="text-sm font-medium">555-555-5555</p>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
