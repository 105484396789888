import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/common/Navbar";
import Register from "./pages/Register";
import { Toaster } from "react-hot-toast";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import Completion from "./pages/Completion";
function App() {
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="h-screen">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/completion" element={<Completion />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
